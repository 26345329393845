import React, { useState, useEffect, useContext } from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

import './invitation-list.scss'

import jwt, {
  hasPermission,
  hasRole
} from '../components/Auth'

import { getInvitations } from '../services/api/invitations'
import { toast } from 'react-toastify'
import { putUser } from '../services/api/users'
import { Context } from '../Context'
import { LoginForm } from '../components/LoginForm'

export default function InvitationListPage () {
  const { isAuth } = useContext(Context)

  // create_invitation (id=116)
  if (!hasPermission(116)) {
    return (
      <Layout className='page-invitation'>
        <h1>Acceso restringido</h1>
      </Layout>
    )
  }

  const [invitations, setInvitations] = useState({ invitations: [], users: [] })

  // get invitations from backend
  useEffect(() => {
    const args = {
      action: 'with-users'
    }

    if (!hasRole(1)) {
      args.author = jwt().payload.user_id
    }

    getInvitations(args)
      .then(res => {
        if (res.status === 200) {
          const users = res.data.users || []
          setInvitations({
            ...res.data,
            users
          })
        }
      })
      .catch(() => {
        toast.error('No fue posible obtener las invitaciones vigentes')
      })
  }, [])

  // Indica si ya existe el usuario y aún no está activo
  function inactiveUserExists (email) {
    const user = invitations.users.findIndex(item => {
      return item.email === email && item.active === 0
    })

    return user !== -1
  }

  function onActivate (email) {
    const user = invitations.users.find(i => i.email === email)

    if (!user) return

    putUser({
      args: { action: 'activate' },
      body: { id: user.id }
    })
      .then(res => {
        if (res.status === 200) {
          user.active = 1

          setInvitations({
            ...invitations,
            users: [
              ...invitations.users.filter(i => i.id !== user.id),
              user
            ]
          })

          toast.success(`${user.email} activado`)
        }
      })
      .catch(() => {
        toast.error('No fue posible activar el usuario')
      })
  }

  if (!isAuth) {
    return <LoginForm />
  }

  return (
    <Layout className='page-invitation-list'>
      <h1>Lista de invitaciones</h1>

      <div style={{ textAlign: 'center' }}>
        <Link
          to='/invitation/'
          style={{
            color: 'white',
            border: '1px solid white',
            display: 'inline-block',
            padding: '0.25rem 1rem'
          }}
        >Invitar a alguien
        </Link>
      </div>

      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Email</th>
            <th>Email Alternativo</th>
            <th>Teléfono</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {
            invitations.invitations.map(invitation => {
              return (
                <tr key={invitation.id}>
                  <td>{invitation.id}</td>
                  <td>{invitation.fullname}</td>
                  <td>{invitation.email}</td>
                  <td>{invitation.email2}</td>
                  <td>{invitation.phone}</td>
                  <td>{invitation.status ? 'Sin respuesta' : 'Aceptada'}</td>
                  <td>
                    {!inactiveUserExists(invitation.email) ? '-' : (
                      <button className='btn' onClick={() => onActivate(invitation.email)}>Activar</button>
                    )}
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </Layout>
  )
}
